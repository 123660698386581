<template>
  <div class="chatall" :style="{ height: sheight + 'px' }">
    <div class="chat-left">
      <div class="search-box">
        <el-input
            :clearable="true"
            placeholder="搜索"
            prefix-icon="el-icon-search"
            v-model="searchinput"
        >
        </el-input>
      </div>
      <div class="msg-list">
        <!-- <div class="ml-tit font18" v-if="searchstuts">全部消息</div> -->
        <div class="ul-box" :style="{ height: sheight - 50 + 'px' }">
          <div class="userBox"
               v-for="(item, index) in searchUserlist || userlist" :key="index"
               @click="chooseItem(item, index)" >
              <!-- item.meta.payload.ext -->
              <a type="text" size="mini" slot="reference">
                <div @contextmenu.prevent="onContextmenu($event, item)">
                  <div class="user-list"
                       :class="{'m-active':liststuts === item.companyUserId,}"
                  >
                    <div class="ul-pic">
                      <img
                          v-if="item.from === 'free_company_' + item.companyUserId"
                          :src = "item.ext.chat_attribute_avatar_from
                              ? 'https://freemen.work/user/file/view/' + item.ext.chat_attribute_avatar_from
                              : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                          "
                      />
                      <img
                          v-else
                          :src= "item.ext.chat_attribute_avatar_to
                              ? 'https://freemen.work/user/file/view/' + item.ext.chat_attribute_avatar_to
                              : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                          "
                      />
                    </div>
                    <div class="msg-info">
                      <div class="mi-one">
                        <div class="name font16">
                          {{
                            item.from === 'free_company_' + item.ext.chat_attribute_companyUserId
                                ? item.ext.chat_attribute_nickname_from
                                : item.ext.chat_attribute_nickname_to
                          }}
                          <span v-if="item.unread_num > 0">{{
                              item.unread_num
                            }}</span>
                        </div>
                        <div class="time font14">
                          {{ getTime(item.timestamp) }}
                        </div>
                      </div>
                      <div class="mi-two font14" v-if="item.bodies.length > 0 &&  item.bodies[0].type === 'txt'">
                        {{
                          item.bodies[0].msg === '###freeman-message-recall-content###'
                            ? item.from === 'free_company_' + item.companyUserId
                              ? "对方撤回了一条消息"
                              : "你撤回了一条消息"
                            : item.bodies[0].msg
                        }}
                      </div>
                      <div class="mi-two font14" v-if="item.bodies.length > 0 &&  item.bodies[0].type === 'img'">
                        {{"[ 图片 ]"}}
                      </div>
                      <div class="mi-two font14" v-else-if="item.bodies.length > 0 &&  item.bodies[0].type === 'loc'">
                        {{ "[ 地址 ]" }}
                      </div>
                      <div class="mi-two font14" v-if="item.bodies[0].msg === 'cmpy_get_phone'">
                        {{" 对方申请获得您的手机号码 "}}
                      </div>
                      <div class="mi-two font14" v-if="item.bodies[0].msg === 'tech_reject_get_phone'">
                        {{" 您拒绝了对方的手机号申请 "}}
                      </div>
                      <div class="mi-two font14" v-if="item.bodies[0].msg === 'tech_agree_get_phone'">
                        {{" 您已同意对方的手机号申请 "}}
                      </div>
                      <div class="mi-two font14" v-if="item.bodies[0].msg === 'cmpy_get_wechat'">
                        {{" 对方申请获得您的微信号码 "}}
                      </div>
                      <div class="mi-two font14" v-if="item.bodies[0].msg === 'tech_reject_get_phone'">
                        {{" 您拒绝了对方的微信号申请 "}}
                      </div>
                      <div class="mi-two font14" v-if="item.bodies[0].msg === 'tech_agree_get_wechat'">
                        {{" 您已同意对方的微信号申请 "}}
                      </div>
                      <div class="mi-two font14" v-else>
                        {{      }}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-right">
      <!-- 右侧查看所有沟通过的牛人消息哦 -->
      <div class="youce-look" :style="{ height: sheight + 'px' }" v-if="false">
        <img src="../../assets/images/common/position-nodata.png" />
        <div class="">右侧查看所有沟通过的牛人消息哦</div>
      </div>
      <!-- 技术者端与企业聊 -->
      <div class="chat-main" v-if="userlist && userlist.length > 0">
        <div class="tab-box">
          <div class="tb-main" :class="activenum == 1 ? 'tb-active' : ''" @click="changeNum(1)">
            聊天
          </div>
          <div class="tb-main" :class="activenum == 2 ? 'tb-active' : ''" @click="changeNum(2)">
            企业信息
          </div>
        </div>
        <!-- tab聊天 界面 -->
        <div class="" v-if="activenum === 1">
          <div class="chat-header font16">
            <!-- 聊天对象 name -->
            <div class="c-name">
              {{
                current.displayName === selectCompanyUser.ext.chat_attribute_nickname_from
                    ? selectCompanyUser.ext.chat_attribute_nickname_to
                    : selectCompanyUser.ext.chat_attribute_nickname_from
              }}
            </div>

            <img src="../../assets/images/common/boy.png"
                v-if="selectCompanyUser.ext.chat_attribute_gender_from === 1" />
            <img src="../../assets/images/common/girl.png"
                v-if="selectCompanyUser.ext.chat_attribute_gender_from === 0" />
            <div class="gs-name">
              {{ companyDetail.shortName }}
            </div>
          </div>
          <div class="baioti">
            正在沟通岗位：
            <span style="cursor: pointer;" @click="toJobDetailPage(selectCompanyUser.ext.chat_attribute_position_id, selectCompanyUser.ext.chat_attribute_job_intention_id)">
              {{ selectCompanyUser.ext.chat_attribute_position_name }}</span>
<!--            <span style="cursor: pointer;" @click="testEMPush">-->
<!--              {{ "Test" }}</span>-->
          </div>
          <div class="chat-content" ref="chatbox" :style="{ height: sheight - 350 + 'px' }">
            <!-- recordContent 聊天记录数组-->
            <div v-for="(itemc, indexc) in recordContent" :key="indexc">
              <!-- 对方 -->
              <div class="word">
                <template v-if="messageSort(itemc) > 10">
                  <div class="c-time">
                    {{ getTime(itemc.time)}}
                  </div>
                  <!-- 求职者头像及聊天内容-->
                  <div class="infobox" v-if="itemc.from === 'free_company_' + selectCompanyUser.companyUserId">
                    <img
                        :src="selectCompanyUser.ext.chat_attribute_avatar_from
                          ? 'https://freemen.work/user/file/view/' + selectCompanyUser.ext.chat_attribute_avatar_from
                          : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                      "
                    />
                    <div class="info">
                      <!-- <p class="time">无名氏 2021.12.10</p> -->
                      <img
                          class="pic_content"
                          style="width: auto; max-width: 200px; height: auto; padding: 6px 0 0 14px; border-radius: 0;"
                          v-if="messageSort(itemc) === 12"
                          :src="itemc.url"
                          @click="lookbooks(itemc.url)"
                      />
                      <div class="addressbox loc_content"
                           v-else-if="messageSort(itemc) === 14">

                        <el-amap class="ditubox" :zoom="itemc.data.zoom" :center="itemc.data.center" :mapStyle="mapStyle">
                          <el-amap-marker
                              v-for="(marker, index) in itemc.data.markers"
                              :position="marker.position"
                              :events="marker.events"
                              :vid="index"
                              :icon="marker.icon"
                              v-bind:key="index"
                          >
                          </el-amap-marker>
                          <el-amap-info-window
                              v-if="itemc.data.window"
                              :position="itemc.data.window.position"
                              :visible="itemc.data.window.visible"
                              :content="itemc.data.window.content"
                              :offset="itemc.data.window.offset"
                              :is-custom="true"
                          >
                            <div id="info-window">
                              <p>{{ itemc.data.markers[0].text }}</p>
                            </div>
                          </el-amap-info-window>
                        </el-amap>
                      </div>
                      <div v-else class="info-content">{{ itemc.data}}</div>
                    </div>
                  </div>
                  <!-- 我方 招聘者头像及聊天记录-->
                  <div class="word-my" v-else>
                    <div class="info">
                      <!-- 2分钟可以支持消息撤回， 2分钟以后不支持消息撤回，不能撤回则放到div中-->
                      <div v-show="Date.now() - itemc.time >= 2 * 60 * 1000">
                        <img
                            class="pic_content"
                            style="padding: 6px 14px 0 0;"
                            v-if="messageSort(itemc) === 12"
                            :src="itemc.url"
                            @click="lookbooks(itemc.url)"
                        />
                        <div v-else class="info-content">
                          {{ itemc.data?(itemc.data).replace(/^\s+|\s+$/g,''):itemc.data }}
<!--                          {{ itemc.data }}-->
                        </div>
                      </div>
                      <el-popover
                          v-show="Date.now() - itemc.time < 2 * 60 * 1000"
                          popper-class="popper"
                          placement="bottom"
                          width="58"
                          trigger="click"
                          v-model="itemc.visible" >
                        <a type="text" size="mini" slot="reference">
                          <div @contextmenu.prevent="showRall(itemc)">
                            <img
                                class="pic_content"
                                style="padding: 6px 14px 0 0;"
                                v-if="messageSort(itemc) === 12"
                                :src="itemc.url"
                                @click="lookbooks(itemc.url)"
                            />
                            <div v-else class="info-content">
                               {{ itemc.data?(itemc.data).replace(/^\s+|\s+$/g,''):itemc.data }}
<!--                              {{ itemc.data }}-->
                            </div>
                          </div>
                        </a>
                        <div ref="rightRecallMenu"
                            style="display: inline-block; margin: 0; padding: 12px; cursor:pointer;"
                            @click="mouseclick(itemc)"
                            @mousedown="switchRightRecallMenu"
                        >
                          撤回
                        </div>
                      </el-popover>
                    </div>
                    <img :src="itemc.ext.chat_attribute_avatar_from !== '' && itemc.ext.chat_attribute_avatar_from !== null
                          ? 'https://freemen.work/user/file/view/' + itemc.ext.chat_attribute_avatar_from
                          : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                      "
                    />
                  </div>
                </template>
                <!-- 消息撤回 / 联系方式授权 自定义消息体 -->
                <div v-if="messageSort(itemc) === 1" class="recall">
                  <div class="recallTips">对方撤回了一条消息</div>
                </div>
                <div v-if="messageSort(itemc) === 2" class="recall">
                  <div class="recallTips">你撤回了一条消息</div>
                </div>
                <div v-if="messageSort(itemc) === 3 && (btnOptionState===0 || btnOptionState===2) && wxContactStatus > 0" class="c-details">
                  <div class="detailsAgreeTips">
                    {{ " 对方申请获得您的微信号码 " }}
                    <el-radio-group style="padding-left: 3px;" ref="btnOptionContactWay" v-if="wxContactStatus === 1">
                      <!-- v-if="btnOptionState===0 || btnOptionState===2" -->
                      <el-radio-button label="agree" @click.native="replyContactWay('agree', itemc, 1)">同意
                      </el-radio-button>
                      <el-radio-button label="reject" @click.native="replyContactWay('reject', itemc, 1)">拒绝
                      </el-radio-button>
                    </el-radio-group>
                    <span style="padding-left: 3px; font-weight: initial;" v-if="wxContactStatus === 2">已失效</span>
                  </div>
                </div>
                <div v-if="messageSort(itemc) === 5 && (btnOptionState===0 || btnOptionState===2) && wxContactStatus === 0" class="c-details">
                  <div class="detailsRejectTips">
                    {{ " 您拒绝了对方的微信号申请 " }}
                  </div>
                </div>
                <div v-if="messageSort(itemc) === 6 && (btnOptionState===1 || btnOptionState===3) && wxContactStatus === 0" class="c-details">
                  <div class="detailsAgreeTips">
                    {{ " 您已同意对方的微信号申请 " }}
                  </div>
                </div>
                <div v-if="messageSort(itemc) === 4 && (btnOptionState===0 || btnOptionState===1) && phoneContactStatus > 0" class="c-details">
                  <div class="detailsAgreeTips">
                    {{ " 对方申请获得您的手机号码 " }}
                    <el-radio-group style="padding-left: 3px;" ref="btnOptionContactWay" v-if="phoneContactStatus === 1">
                      <!-- v-if="btnOptionState===0||btnOptionState===1" -->
                      <el-radio-button label="agree" @click.native.prevent="replyContactWay('agree', itemc, 2)">同意
                      </el-radio-button>
                      <el-radio-button label="reject" @click.native.prevent="replyContactWay('reject', itemc, 2)">拒绝
                      </el-radio-button>
                    </el-radio-group>
                    <span style="padding-left: 3px; font-weight: initial;" v-if="phoneContactStatus === 2">已失效</span>
                  </div>
                </div>
                <div v-if="messageSort(itemc) === 7 && (btnOptionState===0 || btnOptionState===1) && phoneContactStatus === 0" class="c-details">
                  <div class="detailsRejectTips">
                    {{ " 您拒绝了对方的手机申请 " }}
                  </div>
                </div>
                <div v-if="messageSort(itemc) === 8 && (btnOptionState===2 || btnOptionState===3) && phoneContactStatus === 0" class="c-details">
                  <div class="detailsAgreeTips">
                    {{ " 您已同意对方的手机号申请 " }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <div class="c-caozuo">
              <el-tooltip
                  class="item"
                  effect="light"
                  content="表情"
                  placement="top-start"
              >
                <img
                    src="../../assets/images/common/biaoqing.png"
                    @click="(emoji = !emoji), (langstuts = false)"
                />
              </el-tooltip>
              <el-tooltip
                  class="item"
                  effect="light"
                  content="常用语"
                  placement="top-start"
              >
                <img
                    src="../../assets/images/common/changyongyu.png"
                    @click="(langstuts = !langstuts), (emoji = false)"
                />
              </el-tooltip>
              <el-tooltip
                  class="item"
                  effect="light"
                  content="发送图片"
                  placement="top-start"
              >
                <el-upload
                    class="upload-demo"
                    action="https://freemen.work/user/file/upload/"
                    ref="upload"
                    multiple
                    :on-success="handleSuccess"
                >
                  <img
                      src="../../assets/images/common/pic.png"
                      style="width: 26px; height: 26px; padding-top: 2px"
                  />
                </el-upload>
              </el-tooltip>

              <!-- 表情弹窗 -->
              <div class="emoji" v-if="emoji">
                <span @click="emojiClick('😀')">😀</span>
                <span @click="emojiClick('😁')">😁</span>
                <span @click="emojiClick('😂')">😂</span>
                <span @click="emojiClick('😃')">😃</span>
                <span @click="emojiClick('😄')">😄</span>
                <span @click="emojiClick('😅')">😅</span>
                <span @click="emojiClick('😆')">😆</span>
                <span @click="emojiClick('😉')">😉</span>
                <span @click="emojiClick('😊')">😊</span>
                <span @click="emojiClick('😋')">😋</span>
                <span @click="emojiClick('😎')">😎</span>
                <span @click="emojiClick('😍')">😍</span>
                <span @click="emojiClick('😘')">😘</span>
                <span @click="emojiClick('😗')">😗</span>
                <span @click="emojiClick('😇')">😇</span>
                <span @click="emojiClick('😑')">😑</span>
                <span @click="emojiClick('😶')">😶</span>
                <span @click="emojiClick('😏')">😏</span>
                <span @click="emojiClick('😣')">😣</span>
                <span @click="emojiClick('😥')">😥</span>
                <span @click="emojiClick('😮')">😮</span>
                <span @click="emojiClick('😯')">😯</span>
                <span @click="emojiClick('😪')">😪</span>
                <span @click="emojiClick('😫')">😫</span>
                <span @click="emojiClick('😴')">😴</span>
                <span @click="emojiClick('😌')">😌</span>
                <span @click="emojiClick('😛')">😛</span>
                <span @click="emojiClick('😜')">😜</span>
                <span @click="emojiClick('😝')">😝</span>
                <span @click="emojiClick('😒')">😒</span>
                <span @click="emojiClick('😓')">😓</span>
                <span @click="emojiClick('😔')">😔</span>
                <span @click="emojiClick('😕')">😕</span>
                <span @click="emojiClick('😲')">😲</span>
                <span @click="emojiClick('😷')">😷</span>
                <span @click="emojiClick('😖')">😖</span>
                <span @click="emojiClick('😞')">😞</span>
                <span @click="emojiClick('😟')">😟</span>
                <span @click="emojiClick('😤')">😤</span>
                <span @click="emojiClick('😢')">😢</span>
                <span @click="emojiClick('😭')">😭</span>
                <span @click="emojiClick('😦')">😦</span>
                <span @click="emojiClick('😧')">😧</span>
                <span @click="emojiClick('😨')">😨</span>
                <span @click="emojiClick('😬')">😬</span>
                <span @click="emojiClick('😰')">😰</span>
                <span @click="emojiClick('😱')">😱</span>
                <span @click="emojiClick('😳')">😳</span>
                <span @click="emojiClick('😵')">😵</span>
                <span @click="emojiClick('😡')">😡</span>
                <span @click="emojiClick('😠')">😠</span>
              </div>
              <!-- 常用语弹窗 -->
              <div class="langage" v-if="langstuts">
                <img class="xiala" src="../../assets/images/common/xaila.png" />
                <div class="lang-main font14">
                  <div class="lm-text">常用语</div>
                  <div class="set" @click="lanSet">设置</div>
                </div>
                <div
                    class="lang-main font14"
                    v-for="(item, index) in phraseList"
                    :key="index"
                    @click="lanClick(item)"
                >
                  <div class="lm-text">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </div>
            <!-- :autosize="{ minRows: 4, maxRows: 4}" -->
            <div class="chat-input-box">
              <el-button id="btnSend" class="send_msg" @click="sendMes">发送</el-button>
              <el-input
                  id="input"
                  class="input-textarea"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4 }"
                  placeholder=""
                  v-model="inputinfo"
                  @keyup.enter.native="sendMes()"
              >
              </el-input>
            </div>
          </div>
        </div>
        <!-- 企业信息界面 -->
        <div
            class="qy-info"
            :style="{ height: sheight - 140 + 'px' }"
            v-if="activenum == 2"
        >
          <div class="gi-left">
            <div class="gl-pic">
              <img
                  v-if="companyDetail.companyAvatar"
                  :src="
                  companyDetail.companyAvatar !== '' && companyDetail.companyAvatar !== null
                    ? 'https://freemen.work/user/file/view/' +
                      companyDetail.companyAvatar
                    : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                "
              />
              <img v-else src="../../assets/logo.png" />
            </div>
            <div class="gl-info font14">
              <div class="font20">{{ companyDetail.shortName }}</div>
              <div class="">
                {{
                  companyDetail.companyType &&
                  companyDetail.companyType.itemText + "|"
                }}
                {{
                  companyDetail.companySize &&
                  companyDetail.companySize.itemText
                }}
              </div>
              <div class="">
                <span
                    v-for="(item, index) in companyDetail.companyWelfare"
                    :key="index"
                >
                  {{ item && item.itemText }}
                  <span v-if="index < companyDetail.companyWelfare.length - 1"
                  >|</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="gs-jianjie">
            <div class="p-tit"><span>公司介绍</span></div>
            <div class="gs-jieshao font14">
              <div class="">{{ companyDetail.companyDescription }}</div>
            </div>
          </div>
          <div class="">
            <div class="photobox">
              <div class="p-tit"><span>公司相册</span></div>
              <div class="p-main">
                <el-carousel interval="2000" type="card" height="157px">
                  <el-carousel-item v-for="item in picList" :key="item">
                    <img
                        style="height: 157px;"
                        :src="
                        item != ''
                          ? 'https://freemen.work/user/file/view/' + item
                          : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
                      "
                    />
                  </el-carousel-item>
                </el-carousel>
              </div>

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 无记录 -->
      <div class="nodata" v-else>
        <img src="../../assets/images/common/position-nodata.png" />
        <div class="font18">暂无消息</div>
      </div>
    </div>

    <el-dialog
        :visible.sync="dialogVisible"
        width="600px"
        :show-close="false"
        :modal-append-to-body="false"
        :lock-scroll="false"
    >
      <div style="padding: 60px 40px;">
        <div
            type="card"
            style="display: flex; align-items: center; justify-content: center;"
        >
          <img style="width:600px" :src="previewUrl" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import location from "../../assets/images/size.png";
import WebIM from "easemob-websdk";
import Vue from 'vue'
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);
let timer = null;
let serchTimer = null;
export default {
  inject:['reload'],
  data() {
    return {
      active: 0,
      emojiObj: WebIM.emoji,
      sheight: "",
      searchinput: "",
      userlist: [],
      searchUserlist: null,
      activenum: 1,
      recordContent: [
        // mineMsg,headUrl,nickName,timestamp,contactText
        // {id:1,mineMsg}
      ],
      allRecoed: {},
      inputinfo: "",
      langstuts: false,
      // 当前选中的聊天对象
      liststuts: 0,
      phraseList: [],
      emoji: false,
      companyDetail: {},
      selectCompanyUser: {},
      picList: [],
      // 是否显示弹窗
      dialogVisible: false,
      // 预览图
      previewUrl: "",
      current: {},
      onMsgPadding: false,
      // 当前用户微信、手机号申请状态 0:未曾同意申请, 1:微信, 2:手机号, 3：微信+手机号
      btnOptionState: 0,
      // 微信联系方式显示状态 -1隐藏 0显示已操作 1显示可操作 2显示不可操作
      wxContactStatus: -1,
      // 手机号码方式显示状态 -1隐藏 0显示已操作 1显示可操作 2显示不可操作
      phoneContactStatus: -1,
      // 消息发送标志
      sendingFlag: false,
      //设置地图样式
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a",
    };
  },
  created() {
    // console.log("----------created-------------");
    window.noIMlogin = true;
    // this.$conn.listen
    this.init();
  },
  destroyed() {
    // this.$IM.outLogin();
    this.$IM.removeEventHandlerAll('all');
    window.removeEventListener("click", this.showRall);
  },
  watch: {
    searchinput(val) {
      clearTimeout(serchTimer);
      serchTimer = setTimeout(() => {
        if (val.length > 0) {
          this.searchUserlist = this.userlist.filter((v) => {
            const info = v;
            const { ext } = info;
            const name =
                info.from === "free_company_" + info.companyUserId
                    ? ext.chat_attribute_nickname_from
                    : ext.chat_attribute_nickname_to;
            // console.log("--------watch-------- search input", name);
            return name.indexOf(val) !== -1;
          });
        } else {
          this.searchUserlist = null;
        }
      }, 100);
    },
    $route(val) {
      this.userlist = [];
      this.searchUserlist = null;
      this.searchinput = "";
      this.active = val.query.active-0;
      this.getMes("init");
    },
  },
  methods: {
    testEMPush(){
      this.$api.testEMPush("post", {content:"你好，Hello, こんにちは, 안녕하세요!"}).then((res) => {
        console.log("test push!!!! res: ", res);
      })
    },
    init(){
      this.current = JSON.parse(sessionStorage.getItem("userInfo"));
      // console.log("--- chat init, current user info = ", this.current);

      let tempHeight = document.documentElement.scrollHeight;
      this.sheight = tempHeight > 960 ? 760 : tempHeight < 400 ? 760 : (tempHeight -160);
      // console.log("--- chat init, current page height = ", this.sheight);

      if(window.IMlogin){
        this.initIMData();
        this.initIMListener();
      } else {
        clearTimeout(timer);
        console.log("等待登陆");
        timer = setTimeout(() => {
          this.init();
        }, 500);
      }
    },
    initIMData(){
      // console.log("created in chat------------init data----");
      window.addEventListener("click", this.showRall); // 关闭撤回弹窗
      this.$IM.refreshContentCatch();
      this.getMes("init");
      this.getPhraseList();
    },
    initIMListener(){
      // console.log("created in chat------------set noIMlogin, add listen----");
      this.$IM.removeEventHandlerAll('all');
      this.$IM.onCustomMessage((message) => {
        // console.log("IM.onCustomMessage 数据接收成功 message----", message)
        if(message.customEvent){
          if(message.customEvent === "cmpy_get_wechat"
              || message.customEvent === "tech_reject_get_wechat"
              || message.customEvent === "tech_agree_get_wechat"){
            this.wxContactStatus = 1;
          }
          if (message.customEvent === "cmpy_get_phone"
              || message.customEvent === "tech_reject_get_phone"
              || message.customEvent === "tech_agree_get_phone"){
            this.phoneContactStatus = 1;
          }
        }
        if(this.onReceiveUpdate(message)){
          this.getMes('custom');
        }
      });
      this.$IM.onLocationMessage((message) => {
        // console.log("IM.onLocationMessage 数据接收成功 message----", message)
        if(this.onReceiveUpdate(message)){
          this.getMes('loc');
        }
      });
      this.$IM.onTextMessage((message) => {
        // console.log("IM.onTextMessage 数据接收成功 message----", message)
        if(this.onReceiveUpdate(message)){
          this.getMes('txt');
        }
      });
      // this.$IM.onPictureMessage((message) => {
      //   // debugger
      //   console.log("IM.onPictureMessage 数据接收成功 message----", message)
      // })

      this.$IM.onImageMessage((message)=>{
        // console.log("onImageMessage in chat-------------", message);
        if(this.onReceiveUpdate(message)){
          this.getMes('img');
        }
      });
    },
    onReceiveUpdate(message){
      // debugge
      let tempContent = this.dealMessage2Content(message);  // message = {}
      // console.log("onReceiveMessage ----- deal the message to content = ", tempContent);
      if (this.allRecoed[message.from]) {
        // console.log("this.allRecoed[", message.from, "].push(", tempContent, ")---------")
        this.allRecoed[message.from].push(tempContent);
      } else {
        // console.log("this.allRecoed[", message.from, "].push(", message, ") with a new[]---------")
        this.allRecoed[message.from] = [];
        this.allRecoed[message.from].push(tempContent);
      }
      return true;
    },
    dealMessage2Content(message) {  // 整理IM返回消息结构，处理并入聊天记录content
      // console.log("deal RecordContent, message = ", message, ", message.length = ", message.length, ", and message.isArray = ", Array.isArray(message));
      let tempUserId = -1;
      if(Array.isArray(message)){
        let configs = [];
        message.map((item) => {
          if (item.ext.chat_attribute_companyUserId) {
            if (item.from.split('_')[1] === 'company') {
              tempUserId = item.from.split('_')[2];
            } else {
              tempUserId = item.to.split('_')[2];
            }
          } else {
            tempUserId = item.ext.chat_attribute_companyUserId;
          }
          let dataType = item.contentsType ? item.contentsType
              : item.ext.chat_attribute_type ? item.ext.chat_attribute_type
                  : item.type;
          let dataContent;
          if (dataType === 'loc' || dataType === 'LOCATION') {
            let tempMarkers = [];
            tempMarkers.push({
              position: [item.lng, item.lat],
              // icon: new AMap.Icon({
              //   image: location,
              //   size: new AMap.Size(40, 40),
              //   imageSize: new AMap.Size(40, 40),
              // }),
              icon: "", //图片ip
              imageSize: "20px",
              text: item.ext.chat_attribute_address_detail,
              offset: [0, -50],
            });
            dataContent = {
              center: [item.lng, item.lat],
              markers: tempMarkers,
              window: {
                position: [item.lng, item.lat],
                isCustom: true,
                offset: [10, -40], // 窗体偏移
                visible: true, // 初始是否显示
                address: item.addr,
              },
              zoom: 14,
            };
          } else if (dataType === 'custom' || dataType === 'CUSTOM') {
            dataContent = item.customEvent;
          } else {
            dataContent = item.data;
          }
          let config = {
            id: item.id,
            userId: tempUserId,
            type: dataType,
            time: item.time,
            visible: false,
            from: item.from,
            to: item.to,
            data: dataContent,
            url: item.url ? item.url : "",
            ext: item.ext,
          }
          // console.log("update the record content, config = ", config);
          configs.push(config);
        });
        return configs;
      } else {
        // 收到单条消息 处理
        if (message.ext !== undefined || message.ext.chat_attribute_companyUserId) {
          if (message.from.split('_')[1] === 'company') {
            tempUserId = message.from.split('_')[2];
          } else {
            tempUserId = message.to.split('_')[2];
          }
        } else {
          tempUserId = message.ext.chat_attribute_companyUserId;
        }
        // 判断新消息附带 '正在沟通岗位' 信息是否变更
        if (message.ext.chat_attribute_position_id
            && message.ext.chat_attribute_position_id !== this.selectCompanyUser.ext.chat_attribute_position_id){
          this.selectCompanyUser.ext.chat_attribute_position_id = message.ext.chat_attribute_position_id;
          this.selectCompanyUser.ext.chat_attribute_position_name =
              message.ext.chat_attribute_position_id ? message.ext.chat_attribute_position_name : this.selectCompanyUser.ext.chat_attribute_position_name;
          this.$message({
            message: "对方切换了在聊岗位，当前沟通岗位 " + this.selectCompanyUser.ext.chat_attribute_position_name,
            type: "info",
          });
        }

        let dataType = message.contentsType ? message.contentsType
            : message.ext.chat_attribute_type ? message.ext.chat_attribute_type
                : message.type;
        let dataContent;
        if (dataType === 'loc') {
          let tempMarkers = [];
          tempMarkers.push({
            position: [message.lng, message.lat],
            // icon: new AMap.Icon({
            //   image: location,
            //   size: new AMap.Size(40, 40),
            //   imageSize: new AMap.Size(40, 40),
            //
            // }),
            text: message.ext.chat_attribute_address_detail,
            offset: [0, -50],
            icon: "", //图片ip
            imageSize: "20px",
          });
          dataContent = {
            center: [message.lng, message.lat],
            markers: tempMarkers,
            window: {
              position: [message.lng, message.lat],
              isCustom: true,
              offset: [10, -40], // 窗体偏移
              visible: true, // 初始是否显示
              address: message.addr,
            },
            zoom: 14,
          };
        } else if (dataType === 'custom' || dataType === 'CUSTOM') {
          dataContent = message.customEvent;
        } else {
          dataContent = message.data;
        }
        let config = {
          id: message.id,
          userId: tempUserId,
          type: dataType,
          time: message.time,
          visible: false,
          from: message.from,
          to: message.to,
          data: dataContent,
          url: message.url ? message.url : "",
          ext: message.ext,
        }
        return config;
      }
    },
    onContextmenu(event, data) {
      this.$contextmenu({
        items: [
          {
            label: "标记未读",
            disabled: data.unread_num,
            divided: true,
            onClick: () => {
              // console.log("key = markRead", this.userlist);
              this.userlist = this.userlist.map((v) => {
                const id = v.channel_id;
                return id === data.channel_id ? { ...v, unread_num: 1 } : v;
              });
            }
          },
          { label: "标记已读",
            disabled: !data.unread_num,
            divided: true,
            onClick:() => {
              console.log("key = unread", this.userlist);
              this.getMes('init');
            }
          },
          { label: "删除会话",
            onClick:() => {
              // console.log("key = del", this.userlist);
              let userId = data.from.split('_')[1] === 'company' ? data.from : data.to;
              this.$IM
                  .deleteSession({
                    userID: userId,
                  })
                  .then((res) => {
                    console.log("删除会话成功------",res);
                    this.getMes("init");
                  });
            }
          },
        ],
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 3, // 菜单样式 z-index
        minWidth: 130 // 主菜单最小宽度
      });
      return false;
    },
    /* 分类判断消息类型：
    *   1：企业方撤回消息, 2：个人方撤回消息, 0：不是撤回
    *   3：自己发送的微信号码申请, 4:自己发送的手机号码申请,
    *   5:技术者返回拒绝微信申请, 6:技术者返回接受微信申请, 7:技术者返回拒绝手机申请, 8:技术者返回接受手机申请
    **/
    messageSort(item) {
      // console.log("isRecall", item);
      let v1 = item.data === '###freeman-message-recall-content###';   // 判断是否为 撤回消息
      let v2 = item.from === "free_company_" + item.userId;        // 判断消息来源为发送还是接收

      if (v1) {  // 是撤回消息
        // console.log("------------messageSort, 是撤回消息 current data = ", item, "--------------------");
        if (v2) return 1;
        if (!v2) return 2;
      } else {   // 不是撤回消息 back > 2
        // console.log("------------messageSort, current data = ", item, "--------------------");
        if (item.data === "cmpy_get_wechat") return 3;
        if (item.data === "cmpy_get_phone") return 4;
        if (item.data === "tech_reject_get_wechat") return 5;
        if (item.data === "tech_agree_get_wechat") return 6;
        if (item.data === "tech_reject_get_phone") return 7;
        if (item.data === "tech_agree_get_phone") return 8;
        if (item.type === 'txt' || item.type === 'TEXT') return 11;  // 不是获取联系方式相关自定义消息 back > 10
        if (item.type === 'img' || item.type === 'IMAGE') return 12;
        // if(item.type === 'custom' || item.type === "CUSTOM") return 13;
        if (item.type === 'loc' || item.type === 'LOCATION') return 14;
        return 0;
      }
      return -1
    },
    lookbooks(src) {
      this.dialogVisible = true;
      this.previewUrl = src;
    },
    showRall(item = {}) {
      // console.log(this.recordContent, item, "show Recall");
      this.recordContent = this.recordContent.map((v) =>
          v.id === item.id && Date.now() - item.time < 2 * 60 * 1000
              ? { ...v, visible: true }
              : { ...v, visible: false }
      );
    },
    mouseclick(data) {
      // 消息撤回
      if(this.onMsgPadding) return;
      const { id, companyUserId } = data;
      // debugger
      const delFn = () => {
        const key = "free_company_" + companyUserId;
        this.recordContent.map((v, index) => {
          if(v.id === id){
            this.recordContent.splice(index, 1);
          }
        });
        // this.recordContent = this.recordContent.map((v) => v.id !== id ? v : { ...v, recall: true });
        console.info('mouseclick defFN', this.recordContent)
        this.allRecoed = {
          ...this.allRecoed,
          [key]: this.recordContent,
        };
      };
      this.$IM.recallMessage({
        mid: id,
        ...data,
        success: () => {
          delFn();
          // 发送一段消息作为消息撤回的占位符
          console.info('mouseclick recallMessage success', this.recordContent)
          // this.sendRecallMes();
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          this.sendMessage(userInfo, "###freeman-message-recall-content###", "txt");
        },
        fail: (e) => {
          console.log(e, "mouseclick fail");
        },
      });
    },
    scrollToBottom() {
      this.onMsgPadding = false;
      const chatbox = this.$refs.chatbox;
      // console.log("scrollToBottom, height", chatbox.scrollHeight);
      if(!chatbox) return;
      chatbox.scrollTop = chatbox.scrollHeight;
    },
    // 遍历用户列表
    traverseUserList(arr = [], type){
      // console.log("to update userList, current arr----", arr, ", type = ", type);

      // let userInfo = {
      //   bodies: [
      //     { msg: "1111112\n", type: "txt" },
      //   ],
      //   ext: {},
      //   from: "free_company_244",
      //   meta: {},
      //   to: "free_user_313",
      //   typ: "txt",
      //   unread_num: 0,
      //   channel_id: "1023554509583944460",
      //   companyUserId: 0,
      //   timestamp: 0,
      // }

      let tempList = [];
      this.userlist = [];
      for(var i=0; i<arr.length; i++){
        let info = JSON.parse(arr[i].meta.payload);
        info.timestamp = arr[i].meta.timestamp;
        let tempType = info.ext.chat_attribute_type ? info.ext.chat_attribute_type
            : info.bodies[0].type ? info.bodies[0].type
                : info.type;
        info.type = tempType;
        info.channel_id = arr[i].meta.id;
        let tempFlag = info.from.split('_')[1] === 'company'; // 判断消息为 From 还是 To
        let tempUserId = tempFlag ? info.from.split('_')[2] : info.to.split('_')[2];
        info.companyUserId = tempUserId;

        let tempExt = info.ext;
        // tempExt.chat_attribute_avatar_from = this.current.avatar;
        // tempExt.chat_attribute_avatar_to = tempFlag ? info.ext.chat_attribute_avatar_to : info.ext.chat_attribute_avatar_from;
        // tempExt.chat_attribute_nickname_from = this.current.displayName;
        // tempExt.chat_attribute_nickname_to = tempFlag ? info.ext.chat_attribute_nickname_to : info.ext.chat_attribute_nickname_from;
        tempExt.chat_attribute_companyUserId = tempUserId;
        tempExt.chat_attribute_userId = tempFlag ? info.to.split('_')[2] : info.from.split('_')[2];
        tempExt.chat_attribute_handStatus = info.ext.chat_attribute_handStatus ? info.ext.chat_attribute_handStatus : -1,

        info.ext = tempExt;
        // console.log("for map, index = ", i, ", userInfo = ", info);
        tempList.push(info);
      }
      // 去重
      const res = new Map();
      this.userlist = tempList.filter((a) => !res.has(a.companyUserId) && res.set(a.companyUserId, 1));

      // console.log("After traverseUserList.user list", this.userlist);
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
    },
    //获取对话信息, type="", 默认为''，区分消息类型
    getMes(type) {
        this.$IM.getList().then(async (res) => {
          // console.log("getMessage, current get type = ", type, ", get IM list.res = ", res);
          let arr = [];
          if (this.active === 4) {
            this.$api.getOfferList("get", {status: 1}).then(async (sub) => {
              // console.log("active = 4, get Offer list.sub = ", sub);
              let ids = [];
              sub.data.map((list) => {
                ids.push(list.userId);
              });
              res.data.channel_infos.map((item) => {
                if (ids.indexOf(JSON.parse(item.meta.payload).ext.chat_attribute_companyUserId) !== -1) {
                  arr.push(item);
                }
              });
              this.traverseUserList(arr, type);   // 遍历更新到 this.userList
              if(arr.length === 0) return false;
              let tempSelect = 0;
              if (type === 'init') {
                this.chooseItem(this.userlist[tempSelect], tempSelect);
              } else if(type === 'send'){
                this.userlist.map((v,i) => {
                  if(v.companyUserId === this.liststuts && this.liststuts !== 0){
                    tempSelect = i;
                  }
                });
                this.chooseItem(this.userlist[tempSelect], tempSelect);
              } else {
                this.getContent(this.liststuts);
              }
              return false;
            });
          } else if (!this.active || this.active === 1) {
            arr = res.data.channel_infos;
          } else if (this.active == 2) {
            res.data.channel_infos.map((item) => {
              if (item.unread_num > 0) {
                arr.push(item);
              }
            });
          } else if (this.active == 3) {
            res.data.channel_infos.map((item) => {
              if (
                  JSON.parse(item.meta.payload).ext.chat_attribute_handStatus === 1
              ) {
                arr.push(item);
              }
            });
          }

          this.traverseUserList(arr, type);   // 遍历更新到 this.userList
          // console.log(this.userlist, this.active + ": getMes.user list");

          let tempSelect = 0;
          if (type === 'init') {
            this.chooseItem(this.userlist[tempSelect], tempSelect);
          } else if(type === 'send'){
            this.userlist.map((v,i) => {
              if(v.companyUserId === this.liststuts && this.liststuts !== 0){
                tempSelect = i;
              }
            });
            this.chooseItem(this.userlist[tempSelect], tempSelect);
          } else {
            this.getContent(this.liststuts);
          }
        });
    },
    //获取常用语
    getPhraseList() {
      this.$api.getPhraseList("get").then((res) => {
        this.phraseList = res.data;
      });
    },
    emojiClick(data) {
      //表情点击
      this.emoji = false;
      let input = document.getElementById("input");
      let startnum = input.selectionStart;
      if (startnum === undefined) {
        startnum = 0;
      }
      this.inputinfo =
          this.inputinfo.substring(0, startnum) +
          data +
          this.inputinfo.substring(startnum);
      input.focus();
      input.selectionStart = startnum + 1;
    },
    lanSet() {
      //常用语设置
      this.langstuts = !this.langstuts;
      this.$router.push("commonwords");
    },
    lanClick(data) {
      //常用语点击
      this.langstuts = !this.langstuts;
      let input = document.getElementById("input");
      let startnum = input.selectionStart;
      if (startnum === undefined) {
        startnum = 0;
      }
      this.inputinfo =
          this.inputinfo.substring(0, startnum) +
          data.content +
          this.inputinfo.substring(startnum);
      input.focus();
      input.selectionStart = startnum + data.content.length;
    },
    async loadImage(url){
      var img = new Image();
      let res = {};
      img.src = url;
      img.onload = function () {
        res = {
          url: url,
          width: img.width,
          height: img.height
        }
        // console.log(res.url, 'width:' + res.width + ',height:' + res.height);
        return res;
      }
    },
    async handleSuccess(response, file, fileList) {
      //上传成功
      // console.log(response, file, fileList);
      if (this.onMsgPadding) return;
      this.onMsgPadding = true;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let imgUrl = response.data !== ""
          ? "https://freemen.work/user/file/view/" + response.data
          : "https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png";
      let res = {};
      res = this.loadImage(imgUrl);
      if(res.url){
        this.sendMessage(userInfo, res, 'img');
      } else {
        this.sendMessage(userInfo, {url: imgUrl,}, 'img');
      }
    },
    sendMessage(userInfo, content, type){
      // 防止重复发送
      if(this.sendingFlag) return;
      this.sendingFlag = true;

      // console.log("sendMessage company Config = ", this.selectCompanyUser);
      // console.log("sendMessage content =", content, ", type = ",type, ", userInfo = ", userInfo);
      this.$IM.send({
        id: this.selectCompanyUser.companyUserId,
        content: content,
        type: type,
        chat_attribute_avatar_from: userInfo.avatar,
        chat_attribute_avatar_to: this.selectCompanyUser.from.split('_')[1] === 'company' ? this.selectCompanyUser.ext.chat_attribute_avatar_from
                : this.selectCompanyUser.ext.chat_attribute_avatar_to,
        chat_attribute_company_id: this.selectCompanyUser.ext.chat_attribute_company_id,
        chat_attribute_gender_from: userInfo.sex,
        chat_attribute_gender_to: this.selectCompanyUser.from.split('_')[1] === 'company' ? this.selectCompanyUser.ext.chat_attribute_gender_from
                : this.selectCompanyUser.ext.chat_attribute_gender_to,
        chat_attribute_job_intention_id: this.selectCompanyUser.ext.chat_attribute_job_intention_id,
        chat_attribute_nickname_from: userInfo.displayName,
        chat_attribute_nickname_to: this.selectCompanyUser.from.split('_')[1] === 'company' ? this.selectCompanyUser.ext.chat_attribute_nickname_from
                : this.selectCompanyUser.ext.chat_attribute_nickname_to,
        chat_attribute_position_id: this.selectCompanyUser.ext.chat_attribute_position_id,
        chat_attribute_position_name: this.selectCompanyUser.ext.chat_attribute_position_name,
        chat_attribute_handStatus: this.selectCompanyUser.ext.chat_attribute_handStatus,
        chat_attribute_companyUserId: this.selectCompanyUser.companyUserId,
        chat_attribute_userId: this.selectCompanyUser.ext.chat_attribute_userId,
        success: (id, msgId) => {
          // 消息发送成功
          // console.log("$IM.send onSuccess ------ id = ", id, ", msgId = ", msgId);
          this.sendingFlag = false;

          let obj = {
            id: msgId,
            companyUserId: this.selectCompanyUser.companyUserId,
            type: type,
            data: content,
            visible: false,
            url: "",
            from: "free_company_" + userInfo.id,
            to: "free_user_" + this.selectCompanyUser.companyUserId,
            time: new Date().getTime(),
            ext: {
              chat_attribute_type: type,
              chat_attribute_avatar_from: userInfo.avatar,
              chat_attribute_avatar_to: this.selectCompanyUser.from.split('_')[1] === 'company' ? this.selectCompanyUser.ext.chat_attribute_avatar_from
                  : this.selectCompanyUser.ext.chat_attribute_avatar_to,
              chat_attribute_company_id: this.selectCompanyUser.ext.chat_attribute_company_id,
              chat_attribute_gender_from: userInfo.sex,
              chat_attribute_gender_to: this.selectCompanyUser.from.split('_')[1] === 'company' ? this.selectCompanyUser.ext.chat_attribute_gender_from
                  : this.selectCompanyUser.ext.chat_attribute_gender_to,
              chat_attribute_job_intention_id: this.selectCompanyUser.ext.chat_attribute_job_intention_id,
              chat_attribute_nickname_from: userInfo.displayName,
              chat_attribute_nickname_to: this.selectCompanyUser.from.split('_')[1] === 'company' ? this.selectCompanyUser.ext.chat_attribute_nickname_from
                  : this.selectCompanyUser.ext.chat_attribute_nickname_to,
              chat_attribute_position_id: this.selectCompanyUser.ext.chat_attribute_position_id,
              chat_attribute_position_name: this.selectCompanyUser.ext.chat_attribute_position_name,
              chat_attribute_handStatus: this.selectCompanyUser.ext.chat_attribute_handStatus,
              chat_attribute_companyUserId: this.selectCompanyUser.companyUserId,
              chat_attribute_userId: this.selectCompanyUser.ext.chat_attribute_userId,
            },
          }
          // if (type === 'loc') {
          //   let tempMarkers = [];
          //   tempMarkers.push({
          //     position: [content.lng, content.lat],
          //     icon: new AMap.Icon({
          //       image: location,
          //       size: new AMap.Size(40, 40),
          //       imageSize: new AMap.Size(40, 40),
          //     }),
          //     text: content.addDetail,
          //     offset: [0, -50],
          //   });
          //   let dataContent = {
          //     center: [content.lng, content.lat],
          //     markers: tempMarkers,
          //     window: {
          //       position: [content.lng, content.lat],
          //       isCustom: true,
          //       offset: [10, -40], // 窗体偏移
          //       visible: true, // 初始是否显示
          //       address: content.addName,
          //     },
          //     zoom: 14,
          //   };
          //   obj.data = dataContent;
          //   obj.ext.chat_attribute_address_detail = content.addDetail;
          // } else
          if(type === 'img'){
            obj.data = content.url;
            obj.url = content.url;
          } else {
            obj.data = content;
          }
          // console.log("发送成功", "------obj = ", obj, "before add in record, this.recordContent = ", this.recordContent);
          this.inputinfo = "";
          if(this.allRecoed["free_company_" + this.selectCompanyUser.companyUserId]){
            this.allRecoed["free_company_" + this.selectCompanyUser.companyUserId].push(obj);
            this.recordContent = [...this.recordContent, obj];
          } else {
            this.allRecoed["free_company_" + this.selectCompanyUser.companyUserId] = [];
            this.allRecoed["free_company_" + this.selectCompanyUser.companyUserId].push(obj);
            this.recordContent = this.allRecoed["free_user_" + "free_company_" + this.selectCompanyUser.companyUserId];
          }

          // 防止重复发送，去重
          const res = new Map();
          this.recordContent = this.recordContent.filter((a) => !res.has(a.time) && res.set(a.time, 1));

          // console.log("send", type, ", current record content = ", this.recordContent);
          setTimeout(() => {
            this.scrollToBottom();
          }, 0);
        },
        fail: (e) => {
          // 消息发送失败
          this.sendingFlag = false;
          let message = ' ';
          switch(Number(e.type)) {
            case 603:
              message = '被禁言';
              break;
            case 605:
              message = '群组不存在';
              break;
            case 602:
              message = '不在群组或聊天室中';
              break;
            case 504:
              message = '撤回消息时超出撤回时间';
              break;
            case 505:
              message = '未开通消息撤回';
              break;
            case 506:
              message = '没有在群组或聊天室白名单';
              break;
            case 501:
              message = '消息包含敏感词';
              break;
            case 502:
              message = '被设置的自定义拦截捕获';
              break;
            default:
              message = 'im数据异常';
              break;
          }
          console.log("消息发送失败, ",message);
        },
      });

    },
    changeNum(vals) {
      this.activenum = vals;
    },
    switchRightRecallMenu(){
      this.$refs.rightRecallMenu.display = "none";
    },
    // 选择对话框
    chooseItem(data, index = 0) {
      // debugger
      // console.log("Test----msg data, stest", data);
      if(!data) {
        return;
      }
      this.inputinfo = '';
      if(this.liststuts !== 0 && this.liststuts === data.companyUserId){
        if(this.selectCompanyUser === {}){
          this.selectCompanyUser = data;
        }
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
        return;
      }// 禁止重复点击
      this.liststuts = data.companyUserId;
      this.selectCompanyUser = data;
      // console.log("------- update to local selectCompanyUser, this.info = ", this.selectCompanyUser);
      this.$IM.read({
        id: "free_company_" + this.liststuts,
      });
      this.userlist[index].unread_num = 0;

      // 获取联系方式授权状态
      this.getContactWayStatus(data);

      this.recordContent = [];
      this.getContent(this.liststuts);

      let config = {
        companyId: data.ext.chat_attribute_company_id,
      };
      this.$api.getCompanyDetail("get", config)
          .then((res) => {
            this.companyDetail = res.data || {};
            this.picList = res.data.album.split(",");
          })
          .catch((e) => {
            console.log("信息读取错误! ", e);
          });
    },
    // 获取对话信息
    getContent(id) {
      // console.log("getContent data.id = " + id + ", this.allRecoed =", this.allRecoed);

      if (this.allRecoed["free_company_" + id]) {
        this.recordContent = this.allRecoed["free_company_" + id];
        // console.log("update the recordContent by allRecoed =", this.recordContent);
        setTimeout(() => {
          this.scrollToBottom();
        }, 0);
      } else {
        this.$IM.getContent({
          id: "free_company_" + id,
          success: (res) => {
            // console.log("getChatContext.IM.getContent res = ", res);
            this.allRecoed["free_company_" + id] = [];
            this.allRecoed["free_company_" + id] = this.dealMessage2Content(res);  // res = []
            // this.allRecoed["free_company_" + id] = res;
            this.recordContent = this.allRecoed["free_company_" + id];
            // console.log("update the recordContent by IM.getContent =", this.recordContent);
            setTimeout(() => {
              this.scrollToBottom();
            }, 0);
          },
        });
      }
    },
    // 同意联系方式申请
    replyContactWay(eventId, item, type){
      // console.log("replyContactWay event=", eventId ,", type = "+type, "----item = ", item);
      // console.log("replyContactWay, current btnOptionState = ", this.btnOptionState);
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (eventId === "agree"){
        // 处理信息字段, 防止userId 和 jobId 为空
        let companyUserId = item.ext.chat_attribute_companyUserId
                              ? item.ext.chat_attribute_companyUserId : item.from.split("_")[1] === "company"
                                ? item.from.split("_")[2] : item.to.split("_")[2];
        companyUserId = companyUserId - 0;
        let userJobId = item.ext.chat_attribute_job_intention_id ? item.ext.chat_attribute_job_intention_id : 0;
        userJobId = userJobId - 0;
        // 同意申请
        let data = {
          companyUserId: companyUserId,
          content: type===1?userInfo.wxNumber:userInfo.phone,
          type: type,
          userJobId: userJobId,
        };
        // console.log("replyContactWay current.userInfo=", userInfo, "let data =", data);

        this.$api.addContactWay("post", data).then((res) => {
          // console.log("replyContactWay addContactWay.result=", res);

          if (res.code === 10200) {
            // 按钮状态变更
            this.btnOptionState = this.btnOptionState === 0 ? type : 3;
            type === 1 ? (this.wxContactStatus = 0) : (this.phoneContactStatus = 0);

            this.sendMessage(userInfo, type === 1 ? "tech_agree_get_wechat" : "tech_agree_get_phone", 'custom');
          } else {
            // 同意操作失败
            type === 1 ? (this.wxContactStatus = 1) : (this.phoneContactStatus = 1);
            this.$message({
              message: "操作失败，" + res.message,
              type: "error",
            });
          }
        });
      } else {
        // 拒绝申请
        // this.btnOptionState = this.btnOptionState===0?type:3;
        type === 1 ? this.wxContactStatus = 0 : type === 2 ? this.phoneContactStatus = 0 : "";
        this.sendMessage(userInfo, type === 1 ? "tech_reject_get_wechat" : "tech_reject_get_phone", 'custom');
      }
    },
    // 获取当前聊天对象( 技术者 )的联系方式授权状态
    getContactWayStatus(item){

      let data = {
        companyUserId: item.ext.chat_attribute_companyUserId -0,
        content: '',
        type: 0,
        userJobId: item.ext.chat_attribute_job_intention_id - 0,
      };

      this.$api.getChatRecord("get", data).then((res) => {

        // console.log("$getContactWayStatus.getChatRecord addContactWay.result=", res);
        if(res.code === 10200) {
          let contactWayTmpList = res.data;
          if(contactWayTmpList.length === 2){

            this.btnOptionState = 3;
            this.wxContactStatus = -1;
            this.phoneContactStatus = -1;
          } else if (contactWayTmpList.length === 1){

            this.btnOptionState = contactWayTmpList[0].type;
            if(this.btnOptionState === 1){
              this.wxContactStatus = -1;
              this.phoneContactStatus = 2;
            } else if(this.btnOptionState === 2){
              this.wxContactStatus = 2;
              this.phoneContactStatus = -1;
            } else {
              this.wxContactStatus = 2;
              this.phoneContactStatus = 2;
            }
          } else {

            this.btnOptionState = 0;
            this.wxContactStatus = 1;
            this.phoneContactStatus = 1;
          }
          // console.log("$getContactWayStatus.getChatRecord success.btnOptionState = ", this.btnOptionState);
        } else {

          this.btnOptionState = -1;
          this.wxContactStatus = -1;
          this.phoneContactStatus = -1;
          this.$message({
            message: "操作失败，" + res.message,
            type: "error",
          });
        }
      });
    },
    sendMes() {
      //发送消息
      if (/^\s*$/.test(this.inputinfo)) {
        this.$message({
          message: "不要发送空白信息",
          type: "error",
        });
        return;
      }
      if (this.onMsgPadding) return;
      this.inputinfo = this.inputinfo.replace(/^\s+|\s+$/g,'');
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      // console.log("sendMes userInfo = ",userInfo);
      this.sendMessage(userInfo, this.inputinfo, 'txt');
    },
    toJobDetailPage(companyJobId, userJobId) {
      this.$router.push({
        path: "/positiondetail",
        query: { id: companyJobId, userJobId: userJobId },
      });
    },
    getTime(shijianchuo) {
      // console.log("output ShiJianChuo = ", shijianchuo);
      this.$moment.locale("zh-cn");

      return this.$moment
          .unix(shijianchuo / 1000)
          .startOf("minute")
          .fromNow();
    },
  },
};
</script>
<style lang="less">
.c-caozuo {
  .el-upload {
    > img {
      margin-top: 5px;
    }
  }
  .el-upload-list {
    display: none !important;
  }
}
.popper {
  min-width: 50px !important;
  padding: 0;
}
</style>
<style lang="less" scoped>
#info-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.user-list.m-active:focus{
  outline: none!important;
}
.nodata {
  background-color: #ffffff;
  // box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 96px 0;
  margin-top: 20px;
  div {
    color: #111111;
    margin-top: 20px;
  }

  img {
    width: 260px;
    height: 220px;
  }
}
.show{
  display: inline-block;
}
.hide{
  display: none;
}
.chatall {
  width: 100%;
  // height: 1000px;
  background-color: #ffffff;
  display: flex;
  * {
    box-sizing: border-box;
  }

  .chat-left {
    width: 358px;
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: #e7e6e7;
    .search-box {
      height: 50px;
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      /deep/ .el-input__inner {
        border-radius: 0px;
        background-color: #e7e6e7;
        border: none;
      }
    }
    .msg-list {
      flex: 1;
      .ml-tit {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #111111;
        padding-left: 10px;
      }
      .ul-box {
        // height: 370px;
        overflow: auto;
      }
      .ul-box::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 8px;
        height: 1px;
      }
      .ul-box::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #a0a0a0;
      }
      .m-active {
        background-color: #cac8c6 !important;
      }
      .userBox > div {
        width: 100%;
      }
      .user-list:hover {
        cursor: pointer;
        background-color: #cac8c6 !important;
      }
      .user-list {
        height: 60px;
        // background-color: #000080;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        position: relative;
        .nodu {
          width: 150px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 4px;
          position: absolute;
          bottom: -12px;
          right: 18px;
          color: #111111;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 999;
        }
        .ul-pic {
          width: 50px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
          }
          margin-right: 10px;
        }
        .msg-info {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .mi-one {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              color: #111111;
              position: relative;
              span {
                background-color: #ff4444;
                border-radius: 10px;
                width: 18px;
                height: 18px;
                position: absolute;
                top: -6px;
                right: -10px;
                color: #ffffff;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .time {
              color: #999999;
            }
          }
          .mi-two {
            color: #666666;
            width: 152px;
            overflow: hidden;
            font-size: large;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .chat-right {
    // display: flex;
    // flex: 1;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    .youce-look {
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 184px;
        height: 178px;
      }
      div {
        color: #111111;
        font-size: 20px;
        margin-top: 50px;
      }
    }
  }
}
.chat-main {
  width: 935px;
  .tab-box {
    height: 51px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    .tb-main:hover {
      cursor: pointer;
    }
    .tb-main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #111111;
      font-size: 24px;
      height: 100%;
    }
    .tb-active {
      color: #00bcff;
      position: relative;
    }
    .tb-active:after {
      position: absolute;
      content: "";
      width: 90px;
      height: 4px;
      bottom: -2px;
      background: #00bcff;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.qy-info {
  // height: 370px;
  overflow: auto;
}
.qy-info::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px;
  height: 1px;
}
.qy-info::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a0a0a0;
}
.qy-info {
  padding: 30px 100px;
  .gi-left {
    display: flex;
    align-items: center;
    flex: 1;
    background-color: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
    padding: 30px;
    border-radius: 10px;
    .gl-pic {
      margin-right: 20px;
      img {
        width: 70px;
        height: 70px;
      }
    }
    .gl-info {
      color: #666666;
      line-height: 2;
      div:first-child {
        color: #111111;
      }
      span {
        margin: 0 3px;
      }
    }
  }
  .photobox {
    margin-top: 30px;
    background-color: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;
    .p-tit {
      color: #111111;
      font-size: 20px;
      padding: 30px 0 0 30px;

      span {
        position: relative;
      }
      span:after {
        position: absolute;
        content: "";
        width: 40px;
        height: 4px;
        bottom: -12px;
        background: #00bcff;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    // .p-tit{

    // }
    .p-main {
      padding: 50px 80px;
    }
  }
  .gs-jianjie {
    margin-top: 30px;
    background-color: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px;

    .p-tit {
      color: #111111;
      font-size: 20px;
      padding: 30px 0 0 30px;

      span {
        position: relative;
      }
      span:after {
        position: absolute;
        content: "";
        width: 40px;
        height: 4px;
        bottom: -12px;
        background: #00bcff;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .gs-jieshao {
      color: #666666;
      line-height: 1.5;
      padding: 30px;
    }
  }
}
.chat-header {
  padding: 18px 30px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  .c-name {
    color: #111111;
    margin-right: 10px;
  }
  img {
    width: 20px;
    height: 20px;
  }
  .gs-name {
    color: #999999;
    margin-left: 20px;
  }
}
.baioti {
  color: #999999;
  padding: 10px 30px;
  background-color: #ffffff;
  span {
    color: #00bcff;
    margin-left: 6px;
  }
}
.chat-content {
  // height: 370px;
  overflow-y: auto;
}
.chat-content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  height: 1px;
}
.chat-content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a0a0a0;
}
.chat-content {
  // width: 100%;
  padding: 20px 30px;
  .word {
    .c-time {
      // background-color: #000080;
      text-align: center;
      color: #999999;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .infobox {
      display: flex;
      margin-bottom: 20px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .info {
        margin-left: 10px;
        width: auto;
        .time {
          font-size: 12px;
          color: rgba(51, 51, 51, 0.8);
          margin: 0;
          height: 20px;
          line-height: 20px;
          margin-top: -5px;
        }
        .loc_content {
          width: 680px;
          height: 280px;
          border-radius: 0;
        }
        .addressbox {
          .location {
            padding: 15px 20px;
            .el-icon-location-outline {
              color: #00bcff;
              margin-right: 5px;
              font-size: 18px;
            }
            span {
              color: #111111;
            }
          }
          .ditubox {
            max-height: 250px;
            background-color: #409eff;
          }
        }
        .info-content {
          padding: 10px;
          font-size: 14px;
          background: #fff;
          position: relative;
          margin-top: 4px;
          background-color: #f3f3f3;
          border-radius: 0px 20px 0px 20px;
          word-break:break-all;
          word-wrap:break-word;
          white-space:pre-wrap;
        }
        //小三角形
        // .info-content::before {
        // 	position: absolute;
        // 	left: -8px;
        // 	top: 8px;
        // 	content: '';
        // 	border-right: 10px solid #fff;
        // 	border-top: 8px solid transparent;
        // 	border-bottom: 8px solid transparent;
        // }
      }
    }
    .recall {
      display: flex;
      justify-content: center;
      padding:10px 0;
      .recallTips {
        // text-align: center;
        width: fit-content;
        padding: 8px 14px;
        color: black;
        border-radius: 8px;
        background-color: #ebe9e9;
      }
    }
    .c-details {
      display: flex;
      text-align: center;
      justify-content: center;
      margin-top: 10px;
      padding: 10px 5px;
      .detailAgreeBtn{
        background-color: #dff7ff;
        color: #00BCFF;
        font-size: 14px;
      }
      .detailAgreeBtn:focus{
        background-color: #cfcfcf;
      }
      .detailRejectBtn{
        background-color: #e6e6e6;
        color: #000000;
        font-size: 14px;
      }
      .detailRejectBtn:focus{
        background-color: #cfcfcf;
      }
      .detailsRejectTips {
        background-color: #ebe9e9;
        color: #ff8400;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 8px;
      }
      .detailsAgreeTips {
        background-color: #fff0e0;
        color: #ff8400;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 8px;
      }
    }
  }

  .word-my {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .info {
      width: 90%;
      margin-left: 10px;
      text-align: right;
      .pic_content {
        width: auto;
        max-width: 200px;
        height: auto;
        border-radius: 0;
      }
      .loc_content {
        width: auto;
        border-radius: 0;
      }
      .addressbox {
        .location {
          padding: 15px 20px;
          .el-icon-location-outline {
            color: #00bcff;
            margin-right: 5px;
            font-size: 18px;
          }
          span {
            color: #111111;
          }
        }
        .ditubox {
          height: 340px;
          background-color: #409eff;
        }
      }
      .time {
        font-size: 12px;
        color: rgba(51, 51, 51, 0.8);
        margin: 0;
        height: 20px;
        line-height: 20px;
        margin-top: -5px;
        margin-right: 10px;
      }
      .info-content {
        max-width: 100%;
        padding: 10px;
        font-size: 14px;
        float: right;
        margin-right: 10px;
        position: relative;
        margin-top: 4px;
        background: #00bcff;
        color: #ffffff;
        border-radius: 20px 0px 20px 0px;
        text-align: left;
        word-break:break-all;
        word-wrap:break-word;
        white-space:pre-wrap;
      }
      //小三角形
      // .info-content::after {
      // 	position: absolute;
      // 	right: -8px;
      // 	top: 8px;
      // 	content: '';
      // 	border-left: 10px solid #a3c3f6;
      // 	border-top: 8px solid transparent;
      // 	border-bottom: 8px solid transparent;
      // }
    }
  }
}

.chat-input {
  height: 140px;
  border-top: 12px solid #f3f3f3;
  .c-caozuo {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    position: relative;
    .emoji {
      position: absolute;
      top: -90px;
      left: 10px;
      z-index: 999;
      width: 180px !important;
      height: 80px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.15) !important;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      > span {
        cursor: pointer;
      }
      > .emijiBox {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    img {
      width: 25px;
      height: 25px;
      object-fit: fill;
      margin-right: 10px;
    }
    .langage::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 3px;
      height: 1px;
    }
    .langage::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: #a0a0a0;
    }
    .langage {
      position: absolute;
      top: -150px;
      left: 20px;
      z-index: 999;
      width: 300px !important;
      height: 140px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.15) !important;
      overflow: auto;
      .xiala {
        position: absolute;
        bottom: -6px;
        left: 16px;
        width: 16px;
        height: 8px;
      }
      .lang-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        border-top: 1px solid #f4f4f5;
        .lm-text {
        }
        .set {
          color: #00bcff;
        }
      }
    }
  }
  .chat-input-box {
    position: relative;
    height: 100%;
    .send_msg {
    }
    /deep/.el-button {
      position: absolute;
      bottom: 10px;
      right: 30px;
      border-radius: 0px;
      width: 80px;
      height: 32px;
      background: #f3f3f3;
      color: #111111;
      border: none;
      z-index: 999;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /deep/ .el-textarea__inner {
      border-radius: 0px;
      border: none;
      width: 100%;
      color: #111111;
      resize: none;
    }
  }
}
</style>
